import { useRouter } from "next/router";
import { DeviceModel } from "../components/scrcpy/settings";

export const useTargetDeviceLocalIpAddress = () => {
    const router = useRouter();
    const { query } = router;

    if (!query.ip || typeof query.ip != "string") return undefined;

    return query.ip;
};

export const useDeviceModelQueryParam = (): DeviceModel => {
    const router = useRouter();
    const { query } = router;

    if (!query.model || typeof query.model != "string") return "default";

    return query.model as DeviceModel;
};
