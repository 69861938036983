import { useRouter } from "next/router";

export const useStreamingMode = (): "WIRED" | "WIRELESS" => {
    const router = useRouter();
    if (process.env.NEXT_PUBLIC_STREAMING_MODE === "USE_QUERY_PARAM") {
        const { query } = router;

        if (query.mode === "WIRELESS") return "WIRELESS";
        else return "WIRED";
    } else {
        if (process.env.NEXT_PUBLIC_STREAMING_MODE === "WIRELESS")
            return "WIRELESS";
        else return "WIRED";
    }
};
