import {
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
} from "@fluentui/react";
import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";
import { GLOBAL_STATE } from "../state";

export const ErrorDialogProvider = observer((props: PropsWithChildren<{}>) => {
    const isAdbConnectionError = GLOBAL_STATE.errorDialogMessage.includes(
        "Unable to claim interface",
    );
    const isDeviceDisconnectError =
        GLOBAL_STATE.errorDialogMessage.includes(
            "A transfer error has occurred",
        ) ||
        GLOBAL_STATE.errorDialogMessage
            .toLocaleLowerCase()
            .includes("device disconnected");

    let title = "Error";
    let subText = GLOBAL_STATE.errorDialogMessage;

    if (isAdbConnectionError) {
        title = "Connection error!";
        subText =
            "Failed to connect to the device because some other program is connected to the device on this computer.\n\nPlease close programs that communicate with connected Android devices like the ManageXR Device Setup Tool, Oculus Developer Hub, SideQuest, Android File Transfer, and Android Studio. Once these programs are closed, unplug your device, plug it back in, and try again.\n\nIf you're a developer and use ADB directly, you must also kill your ADB server by running this terminal command: `adb kill-server`.\n\nFinally, make sure that you don't have any other tabs of the ManageXR USB Screencasting Tool open.\n\nIf none of these troubleshooting steps work, please restart your computer and try again.";
    } else if (isDeviceDisconnectError) {
        title = "Connection error!";
        subText =
            "Your device was disconnected. Please reconnect your device and restart streaming.";
    }

    return (
        <>
            {props.children}

            <Dialog
                hidden={!GLOBAL_STATE.errorDialogVisible}
                dialogContentProps={{
                    type: DialogType.normal,
                    title,
                    subText,
                }}
            >
                <DialogFooter>
                    <PrimaryButton
                        text={
                            GLOBAL_STATE.errorDialogWithCloseButton
                                ? "CLOSE WINDOW"
                                : "OK"
                        }
                        onClick={
                            GLOBAL_STATE.errorDialogWithCloseButton
                                ? GLOBAL_STATE.closeWindowFromErrorDialog
                                : GLOBAL_STATE.hideErrorDialog
                        }
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
});
